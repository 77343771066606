import type { IExtendedPositionItem } from "@redux/sagas/positions";
import { parsedSymbol } from "@utils/index";

const getBadgeColor = (product: string) => {
  switch (product) {
    case "CNC":
      return "red";
    case "MIS":
      return "orange";
    case "NRML":
      return "purple";
    case "disabled":
      return "gray";
    default:
      return "";
  }
};

const sortDataWithZeroQtyAtLast = (datas: IExtendedPositionItem[]) => datas.sort((a, b) => {
  if (a.qty === 0 && b.qty !== 0) {
    return 1;
  } if (a.qty !== 0 && b.qty === 0) {
    return -1;
  }
  return 0;
});

const getPnLClassName = (netChange: string | number, colorScheme, pnlClasses:any) => {
  let className = "";
  if (Number(netChange) > 0) {
    className = `greenColor_${colorScheme}`
  } else if (Number(netChange) < 0) {
    className = `redColor_${colorScheme}`
  }
  return pnlClasses[className]
}

// adding the fields required for custom sorting sorting
// Inside this function, symbol is what we get from the API i.g. BANKNIFTY03APR24C46000
// and tradingSymbol is what we get after parsing the symbol i.g. BANKNIFTY or IDEA
const sortableData = (positions: IExtendedPositionItem[]) => {
  const sortedData = positions.map((position) => {
    const parsedSymbolData = parsedSymbol(position.symbol, position.exchange, position.token)
    const {
      symbol = "",
      expiryDay = 0,
      expiryMonth = 0,
      expiryWeek = 0,
      expiryYear = 0,
      isWeekly = false,
      strike = 0,
      type = "",
    } = parsedSymbolData || {}
    return {
      ...position,
      expiryDay,
      expiryMonth,
      expiryWeek,
      expiryYear,
      isWeekly,
      strike,
      type,
      tradingSymbol: symbol,
    }
  });
  return sortedData
}

const sortAndFilterPositions = (positions: IExtendedPositionItem[]) => {
  const sortedData = sortableData(positions)
  const filteredData = sortedData.filter((item) => (item.qty !== 0 || item.product !== "C"))
  const finalData = filteredData.sort((a, b) => {
    if (a.qty === 0) return 1;
    if (b.qty === 0) return -1;

    if (a.tradingSymbol !== b.tradingSymbol) {
      return a.tradingSymbol.localeCompare(b.tradingSymbol);
    }

    if (a.tradingSymbol === b.tradingSymbol) {
      if (a.type === 'FUT' && b.type !== 'FUT') return 1;
      if (a.type !== 'FUT' && b.type === 'FUT') return -1;
    }

    if (a.tradingSymbol === b.tradingSymbol && a.type === b.type) {
      if (a.isWeekly && !b.isWeekly) return -1;
      if (!a.isWeekly && b.isWeekly) return 1;
    }

    if (a.expiryYear !== b.expiryYear) {
      return a.expiryYear - b.expiryYear;
    }

    if (a.expiryMonth !== b.expiryMonth) {
      return a.expiryMonth - b.expiryMonth;
    }

    if (a.expiryDay !== b.expiryDay) {
      return a.expiryDay - b.expiryDay;
    }

    return a.strike - b.strike;
  });
  return finalData
}

export {
  getBadgeColor, sortDataWithZeroQtyAtLast, getPnLClassName, sortAndFilterPositions,
}
